@font-face {
  font-family: "RbFontBook";
  src: local("RbFontBook"),
    url("./assets/fonts/rbno3.1-book-webfont.woff") format("woff");
}

@font-face {
  font-family: "RbFontExtraLight";
  src: url("./assets/fonts/rbno3.1-extralight-webfont.woff") format("woff");
}

@font-face {
  font-family: "RbFontLight";
  src: local("RbFontLight"),
    url("./assets/fonts/rbno3.1-light-webfont.woff") format("woff");
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 300ms linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rowList {
  cursor: pointer;

  &:nth-child(even) {
    background: rgba(255, 255, 255, 0.09);
  }
}

.divider {
  margin-bottom: 20px !important;
  width: 30%;
}

.title {
  font-family: "RbFontExtraLight", serif !important;
  color: white !important;
  font-size: 24px !important;
  margin-left: 10px !important;
}

.titleContainer {
  display: flex;
  flex-direction: row;
}

.backList {
  margin-top: 10px;
  color: white;
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: transparent !important;
  -webkit-text-fill-color: #ffffff !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
